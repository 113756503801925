<template>
  <div>
    <div class="container-pa16">
      <v-collapse-group
        :onlyOneActive="true"
        class="list"
        ref="qa_group"
      >
        <v-collapse-wrapper
          v-for="(item, index) in qa"
          :key="item.key"
          class="question"
        >
          <div
            class="space-between flex-container"
            v-collapse-toggle
            v-on:click="setArrow(index)"
          >
            <span class="text-875 text-b q-text">{{ item.q }}</span>
            <img
              class="arrow"
              :class="qa[index].open ? show : hide"
              :src="require('@/assets/imgs/svg/arrow-right.svg')"
            />
          </div>
          <div
            class="my-content text-875 text-b"
            v-collapse-content
          >
            <hr class="bold-hr margin-v-6 margin-h-2" />
            {{ item.a }}
          </div>
        </v-collapse-wrapper>
      </v-collapse-group>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            qa: [
                {
                    q: "請問第一次要如何租借?",
                    a:
                        "請先支付押金或綁定支付後到主頁面，點擊掃描鍵並掃描機台上QRCODE即可租借。",
                    open: false,
                },

                {
                    q: "請問收費方式如何計算?",
                    a:
                        "租借費用為一小時15元，當日上限60元，24小時後，重新計算4小時，累計七天未歸還，則自動扣除420元 (七天費用)，行動電源則贈予租借者。",
                    open: false,
                },
                // {
                //     q: "為何押金已支付但系統卻沒顯示？",
                //     a:
                //         "可能因重複支付導致系統錯誤，請稍待5分鐘待系統修復，若還沒修復請聯繫pluginn.info @gmail.com。",
                //     open: false,
                // },
                // {
                //     q: "我應該如何才能退押金?",
                //     a:
                //         "請至我的錢包內，如果是已支付押金的情況下，直接按退還押金即可",
                //     open: false,
                // },
                // {
                //     q: "為什麼我按了退押金卻還沒收到押金的退費?",
                //     a:
                //         "目前街口支付、APPLE PAY跟GOOGLE PAY都可以快速退還押金，信用卡支付的用戶必須等待發卡行刷退，將會有14-20個工作天，請耐心等待。",
                //     open: false,
                // },
                {
                    q: "為什麼我按下點擊租借沒有反應?",
                    a:
                        "請查看機器上的QRCODE燈是否有閃爍，閃爍中代表機器正在連線，暫時無法歸還或租借，若機器在兩分鐘後還是沒有停止閃爍，請與我們客服中pluginn.info @gmail.com聯繫，我們將盡快為您處理。",
                    open: false,
                },
                {
                    q: "來不及拿出行動電源就鎖住了怎麼辦?",
                    a: "30秒後就可以再次租借，之前的訂單不會成立。",
                    open: false,
                },
                {
                    q: "在哪裡可以借還行動電源?",
                    a: "在首頁的地圖上，只要有U字LOGO的位置都可以租借和歸還。",
                    open: false,
                },
                {
                    q: "我歸還了行動電源，要怎麼結帳?",
                    a: "系統會直接自您綁定的支付方式，依租借時數自動扣款。",
                    open: false,
                },
                {
                    q: "合作加盟",
                    a:
                        "請寄信給我們pluginn.info @gmail.com，我們將派專人為您服務。",
                    open: false,
                },
            ],
            show: "arrow-top",
            hide: "arrow-bottom",
        };
    },
    methods: {
        setArrow(num) {
            let i = 0;
            if (this.qa[num].open == true) {
                this.qa[num].open = !this.qa[num].open;
            } else {
                for (i = 0; i < this.qa.length; i++) {
                    this.qa[i].open = false;
                }
                this.qa[num].open = !this.qa[num].open;
            }
        },
    },
};
</script>
